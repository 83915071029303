import Vue from 'vue'
import i18n from '../i18n'
import appStore from '../store'
import { APP_MUTATIONS } from '../store/mutations'
import { hasAnyPermission,getRoleNameType } from '../misc/auth'
import { USER_TYPE, AVATAR_COLOR_CODES } from '../misc/commons'
import store from '../store'
Vue.mixin({
    computed: {
        _isNotisphere() {
            return appStore.state.userType == USER_TYPE.NOTI
        }
    },
    methods: {
        openExternalLink(url) {
            window.open(url, '_blank')
        },
        _allowedview(type){
            if (type && type.split('/')[0] == 'image') {
                return true
            }
            else if (type && type.split('/')[0] == 'video') {
                return true
            }
            else if (type && type.split('/')[0] == 'application' && type.split('/')[1] == 'pdf') {
                return true
            }
            else {
                return false
            }
        },
        _name2Digit(str) {
            return str ? str.match(/\b(\w)/g).join('').substring(0, 2) : ''
        },
        _checkPermissions(permissions) {
            return permissions ? hasAnyPermission(permissions) : true
        },
        _checkAllPermissions() {
            const userPermissions = () => store.getters.permissions
            const AlluserPermissions = () => store.getters.AllPermissions
            if (userPermissions().length == AlluserPermissions()) {
                return true
            }
            else {
                return false
            }
        },
        _showConfirm(message, options) {
            return new Promise(resolve => {
                if (this.$root.$children[0]) {
                    this.$root.$children[0].$refs.confirm.showConfirm(message, options).then(resp => {
                        resolve(resp)
                    })
                }
            })
        },
        _showOk(message, options) {
            return new Promise(resolve => {
                if (this.$root.$children[0]) {
                    this.$root.$children[0].$refs.confirm.showOk(message, options).then(resp => {
                        resolve(resp)
                    })
                }
            })
        },
        _showNotify(title, message, variant, options) {
            this.$root.$children[0] ? this.$root.$children[0].$refs.notify.addNotify(title, message, variant, options) : ''
        },
        _showToast(message, options) {
            this.$root.$children[0] ? this.$root.$children[0].$refs.toast.addToast(message, options) : ''
        },
        _loadingStart(){
            appStore.commit(APP_MUTATIONS.LOADING_START)
        },
        _loadingEnd(){
            appStore.commit(APP_MUTATIONS.LOADING_END)
        },
        _getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null
        },
        _findObj(array, search_value, search_key = 'value', get_key = 'text') {
            if( !Array.isArray(array) ){
                return
            }
            var obj = array.find( x => x[search_key] === search_value )
            if( typeof obj === 'object' && obj != undefined ) {
                return obj[get_key]
            }
            return
        },
        _translateTextInArray(arr) {
            return arr.map(x => { return { ...x, text: x.text } })
        },
        _getClassIndicateValidation(validationContext) {
            if (validationContext && (validationContext.validated || validationContext.dirty)) {
                return this._getValidationState(validationContext) ? 'valid' : 'fail'
            }
            return ''
        },
        _fetchAvatarVariant(str) {
            let _code = str ? str.length % 6 : 0
            return AVATAR_COLOR_CODES.find(x => x.id === _code).code
        },
    },
})