import { _findObj, toLocalDate, toLocal, actionCellRenderer, recallActionRenderCell, supplierRecallActionRenderCell, supplierLocationActionRenderCell, supplierDisruptionActionRenderCell, providerRecallActionRenderCell,ErResponseActions} from './genericFunctions'
import { NOTI_RECALL_STATUS, OUTPUT_DATE_FORMAT, OUTPUT_SHORTDATE_FORMAT, PROVIDER_COMMUNICATION_TYPE, RECALL_CLASSIFICATIONS, CLASSIFICATIONFILTER, ORGANIZATION_STATUS, PRODUCT_DISRUPTION_TYPE,ALLOCATIONPROCESS,ER_RECALL_STATUS,RECALL_STATUS, SOURCEFILTER,ACTION_TYPE,PROVIDER_RECALL_STATUS,PROVIDER_EVENTLISTING_STATUS,PROVIDERDISRUPTIOSTATUSFILTER} from '../misc/commons'
export const Table_Header_Customization = [
    {
        userId: null,
        Notisphere_Login: {
            Recall_Headers: [],
            Recall_Filters: [],
            Supplier_Headers: [],
            Provider_Headers: []
        },
        Supplier_Login: {
            Recall_Headers: [],
            Disruption_Headers: [],
            Disruption_Filters: [],
            Recall_Filters: [],
            Recall_AffectedLocation_Headers: [],
            Recall_AffectedCustomer_Headers: [],
            Recall_AffectedLocation_Filters: [],
            Recall_AffectedCustomer_Filters: [],
            Location_Headers: [],
            Location_Filters: [],
        },
        Provider_Login: {
            Recall_Headers: [],
            Recall_Filters: [],
            Disruption_Filters: [],
            Disruption_Headers: [],
            LocationVerification_Headers: [],
            LocationVerification_Filters: [],
            Er_Response_Headers: [],
            Er_Response_Filters: []
        },
    }]
export function trimWhiteSpaces(filter, value, filterText) {
    return true
}
export function txtFormatter(filter, value, filterText) {
    return true
}

export function SortingComparator(valueA, valueB, nodeA, nodeB, isDescending) {
    if(isDescending) {
        if(typeof InstallTrigger !== 'undefined'){
            return true
        }
        else{
            if (valueA == valueB) return 0
            return (valueA > valueB) ? 0 : -1
        }
    }
}
export function DateComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue

    if (dateAsString == null) {
        return 0
    }
    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    var dateSplit = dateAsString.split('T')
    var dateParts = dateSplit[0].split('-')
    var day = Number(dateParts[2])
    var month = Number(dateParts[1]) - 1
    var year = Number(dateParts[0])
    var cellDate = new Date(year, month, day)

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1
    }
    return 0
}

export const CustomizedTableHeaders_Key = '1E890D44-A048-4A69-A951-455D739FF8D4'
export const FilterConnections_Key = '243A6021-47A0-4912-A101-C4AB08C053C8'
export const AG_GridCustomizedTableHeaders_Key = '39C7744F-41FD-4EEE-94CA-322331EB046A'
export const Notisphere_AgGrid_Callback_Functions = {
    providerDocumentDownload: null
}
export const Notisphere_Recalls_AgGrid = [
    {
        minWidth: 150, field: 'status_provider', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Complete', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' } }
    },
    { minWidth: 150, field: 'creator_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Creator', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 170, field: 'classification', filterParams: { values: CLASSIFICATIONFILTER }, hide: false, headerName: 'FDA Classification', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto"> <span > ${params.value} </span> </b-button>`
            return ob
        }
    },
    {
        minWidth: 120,
        field: 'status',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT'|| params.value == 'DELIVERY' || params.value == 'BOUNCE') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 100px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120, field: 'communicationDate', hide: false, headerName: 'Recall Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 120, field: 'completeddate', hide: false, headerName: 'Completed Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 90, field: 'days_open', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Days Open', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 95, field: 'source', filter: 'agSetColumnFilter', filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 110, field: 'number_of_providers', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Providers', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'sitecount', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'isCustomizedContent', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: true, headerName: 'Customized Content', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: recallActionRenderCell, suppressColumnsToolPanel: true },
]

export const Notisphere_Recalls_AffectedLocations_AgGrid = [
    { minWidth: 170, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN / Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'location_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], suppressColumnsToolPanel: true },
    { minWidth: 150, field: 'location_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'address1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'address2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'zip_code', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
]
export const Supplier_Disruption_AffectedCustomers_AgGrid = [
    { minWidth: 150, field: 'account_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], suppressColumnsToolPanel: true },
    { minWidth: 150, field: 'email_address', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Email Address', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'first_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'First Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'last_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Last Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'account_number', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Number', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces , filterOptions: ['equals']}, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
]
export const Notisphere_Supplier_AgGrid = [
    { minWidth: 120, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'zip', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'sso', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals']} , hide: false, headerName: 'SSO', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 120, field: 'stt', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                <span style="background: ${_findObj(ORGANIZATION_STATUS, params.value, 'value', 'color')}; width: 98px;
                                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                    ${_findObj(ORGANIZATION_STATUS, params.value)} 
                                </span> 
                            </b-button>`
            return ob
        }
    },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: actionCellRenderer, suppressColumnsToolPanel: true },
]
export const Notisphere_Provider_AgGrid = [
    { minWidth: 120, field: 'name', hide: false, headerName: 'Name', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 150, field: 'notisphere_id', hide: false, headerName: 'Notisphere ID', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add1', hide: false, headerName: 'Address 1', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add2', hide: false, headerName: 'Address 2', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'city', hide: false, headerName: 'City', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'state', hide: false, headerName: 'State', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'zip', hide: false, headerName: 'Zip', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 130,
        field: 'document',
        headerName: 'Agreement',
        hide: false,
        sortable: true,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            var docFrag = document.createDocumentFragment()
            for (let index = 0; index < params.value?.length; index++) {
                let itemdoc = params.value[index]
                let img = null
                var ob = document.createElement('div')
                if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                    img = require('@/assets/img/word.svg')
                } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                    img = require('@/assets/img/adobe.svg')
                }
                if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                    ob.innerHTML = `<button type="button" class="btn btn-sm font-20 font-weight-bold text-primary">
                                    <img src=${img} height="30" alt=""/> 
                                </button>`
                    ob.addEventListener('click', (event) => {
                        if (Notisphere_AgGrid_Callback_Functions.providerDocumentDownload != null && Notisphere_AgGrid_Callback_Functions.providerDocumentDownload != undefined) {
                            Notisphere_AgGrid_Callback_Functions.providerDocumentDownload(itemdoc)
                        }
                    })
                    docFrag.appendChild(ob)
                }
            }
            return docFrag
        }
    },
    {
        minWidth: 130, field: 'stt', hide: false, headerName: 'Status', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab']
        , cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                <span style="background: ${_findObj(ORGANIZATION_STATUS, params.value, 'value', 'color')}; width: 98px;
                                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                    ${_findObj(ORGANIZATION_STATUS, params.value)} 
                                </span> 
                            </b-button>`
            return ob
        }
    },
    { minWidth: 80, field: 'sites', hide: false, headerName: 'Sites', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', suppressSorting: true, cellRenderer: actionCellRenderer, suppressColumnsToolPanel: true
    },
]

export const Supplier_Recalls_AgGrid = [
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 140, field: 'classification', filter: 'agSetColumnFilter', filterParams: {
            values: CLASSIFICATIONFILTER }, hide: false, headerName: 'FDA Classification', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']

    },
    {
        minWidth: 100,
        field: 'status',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 70px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Recall Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 60, field: 'days_open', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Days Open', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 60, field: 'number_of_providers', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Providers', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', suppressMovable: true, sortable: false, cellRenderer: supplierRecallActionRenderCell, suppressColumnsToolPanel: true }
]
export const Provider_Recalls_AgGrid = [
    {
        minWidth: 100, field: 'status_provider', filterParams: {
            values: ['Completed', 'Not Completed']
        }, hide: false, headerName: 'Complete', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab','columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' } }
    },
    { minWidth: 150, field: 'supplier_org_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'distributorName', filterParams: { filterOptions: ['contains', 'blank', 'notBlank'], }, hide: false, headerName: 'Distributor', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationName', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Categories', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']},
    { minWidth: 150, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator ,
        cellRenderer: (params) => {
            if(params.data.recallSiteCount > 1)
            {
                return params.data.recallSiteCount + ' affected sites'
            }
            if(params.data.recallSiteCount == 1)
            {
                return params.data.recallSiteName
            }
            if(params.data.recallSiteCount == 0)
            {
                return params.data.recall_sites
            }
        }

    },

    {
        minWidth: 130,
        field: 'allresponse_doc',
        headerName: 'Response Documents',
        hide: false,
        sortable: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab']
        ,
        cellRenderer: (params) => {
            var docFrag = document.createDocumentFragment()
            if(params.data.allresponse_doc.length > 0)
            {
                for (let index = 0; index < params.data.allresponse_doc?.length; index++) {
                    let itemdoc = params.data.allresponse_doc[index]
                    let img = null
                    let viewclickDom = null
                    var ob = document.createElement('div')

                    if (itemdoc.type == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain') {
                        img = require('@/assets/img/text-format.png')
                    }
                    else if(itemdoc.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.type == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv'){
                        img = require('@/assets/img/excel.svg')
                    }
                    else if(itemdoc.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension == 'docx' || itemdoc.extension == 'doc'){
                        img = require('@/assets/img/word.svg')
                    }
                    else if(itemdoc.type == 'application/pdf' || itemdoc.extension == 'pdf'){
                        img = require('@/assets/img/adobe.svg')
                    }
                    else if (itemdoc.type == 'video/mp4' || itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == 'jpg' || itemdoc.extension == 'svg'  || itemdoc.extension == 'jpeg' || itemdoc.extension == 'gif') {
                        img = require('@/assets/img/image.png')
                    }
                    else if (itemdoc.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx') {
                        img = require('@/assets/img/powerpoint.svg')
                    }
                    else {
                        img = require('@/assets/img/default_icon.png')
                    }
                    if (img !== null && img !== 'null' &&img!== undefined && img !=='' && img !== ' ') {
                        viewclickDom = 'viewclick_' + itemdoc.id
                        ob.innerHTML = `<img class="m-1" src=${img}  data-action=${viewclickDom} height="30" alt=""/>`
                        docFrag.appendChild(ob)
                    }
                }
            }
            return docFrag
        }
    },
    {
        minWidth: 150,
        field: 'affectedProduct',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces,filterOptions: ['contains', 'blank', 'notBlank'], },
        hide: false,
        headerName: 'Affected Product',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (params.data.isProviderCreated && params.data.recallSiteCount == 1) {
                return params.data.affectedProduct
            }
        }
    },
    {
        minWidth: 95, field: 'source',  filter: 'agSetColumnFilter',  filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'date_since_notified', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'contains']}, hide: false, headerName: 'Days Since Notified', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100,
        field: 'status',
        filterParams: {  textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces },
        hide: false,
        headerName: 'Supplier Event Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (!params.data.isProviderCreated) {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto pl-3"> <span > ${_findObj(PROVIDER_RECALL_STATUS, params.value)} </span> </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120,
        field: 'eventstatus',
        filterParams: { values: PROVIDERDISRUPTIOSTATUSFILTER },
        hide: false,
        headerName: 'Provider Event Status',
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (params.data.isProviderCreated && params.value != '' && params.value != null && params.value != undefined) {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                <span style="background: ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value, 'value', 'color')}; width: 98px;
                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                    ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value)} 
                </span> 
            </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120,
        field: 'responseListingStatus', filterParams: {   textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces,filterOptions: ['contains', 'blank', 'notBlank'], },hide: false, headerName: 'Response Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator
        , cellRenderer: (params) => {
            if(params.data.isProviderCreated && params.value != ACTION_TYPE.DELETED && params.value != ACTION_TYPE.NA && params.value != ''){
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                            <span style="background: ${_findObj(RECALL_STATUS, params.value, 'value', 'color')}; width: 98px;
                            font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                ${_findObj(RECALL_STATUS, params.value)} 
                            </span> 
                        </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120, field: 'communicationDate', hide: true, headerName: 'Event Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 130, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: true, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
        return _findObj(PROVIDER_COMMUNICATION_TYPE, params.value) } },
    { minWidth: 110, field: 'classification', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: true, headerName: 'FDA Classification', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
        if (params.value === 'CLASSI' || params.value === 'CLASSII' || params.value === 'CLASSIII' || params.value === 'NOT_YET_CLASSIFIED') {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto"> <span > ${_findObj(RECALL_CLASSIFICATIONS, params.value)} </span> </b-button>`
            return ob
        } }
    },
    {
        minWidth: 120, field: 'responded_at', hide: true, headerName: 'Response Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            //comparator: FilterDateComparator
        }
        , cellRenderer: (params) => {
            if (params?.data.status_provider == 'COMPLETED') {
                if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
                    return null
                return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
            }
        }
    },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: providerRecallActionRenderCell, suppressColumnsToolPanel: true },
]
export const Provider_Disruption_AgGrid = [
    {
        minWidth: 100, field: 'status_provider', filterParams: {
            values: ['Completed', 'Not Completed']
        }, hide: false, headerName: 'Complete', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab','columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' }}
    },
    { minWidth: 150, field:
         'supplier_org_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Disruption ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationName', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 100, field: 'anticipatedduration', hide: false, headerName: 'Anticipated Duration', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == ''|| params.value == 'NA')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( PRODUCT_DISRUPTION_TYPE, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Category', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator },
    { minWidth: 150, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator ,
        cellRenderer: (params) => {
            if(params.data.recallSiteCount > 1)
            {
                return params.data.recallSiteCount + ' affected sites'
            }
            if(params.data.recallSiteCount == 1)
            {
                return params.data.recallSiteName
            }
            if(params.data.recallSiteCount == 0)
            {
                return params.data.recall_sites
            }
        }
    },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Disruption Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 95, field: 'source',  filter: 'agSetColumnFilter',  filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'date_since_notified', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'contains']}, hide: false, headerName: 'Days Since Notified', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 120,
        field: 'eventstatus',filterParams: { values: PROVIDERDISRUPTIOSTATUSFILTER }
        ,hide: false, headerName: 'Status', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator
        , cellRenderer: (params) => {
            if(params.value != ACTION_TYPE.DELETED && params.value != ACTION_TYPE.NA && params.value != '' && params.value != null){
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                            <span style="background: ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value, 'value', 'color')}; width: 98px;
                            font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value)} 
                            </span> 
                        </b-button>`
                return ob
            }
        }
    },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: providerRecallActionRenderCell, suppressColumnsToolPanel: true },
]
export const Supplier_Disruptions_AgGrid = [

    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( PRODUCT_DISRUPTION_TYPE, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100,
        field: 'status',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 70px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Product Disruption ID', filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Disruption Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 100, field: 'anticipatedduration', hide: false, headerName: 'Anticipated Duration', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 120, field: 'allocationprocess', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces },
        cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( ALLOCATIONPROCESS, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: true, headerName: 'Allocation Process', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },

    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: true, headerName: 'Category', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator },
    {
        minWidth: 100, field: 'notificationdate', hide: true, headerName: 'Notification Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },

    {
        minWidth: 100, field: 'lastupdate', hide: true, headerName: 'Last Update', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 100, field: 'nextexpectedupdate', hide: true, headerName: 'Next Expected Update', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    // { minWidth: 120, field: 'createdby', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: true, headerName: 'Created By', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: supplierDisruptionActionRenderCell, suppressColumnsToolPanel: true }
]
export const Supplier_Location_Headers = [
    { minWidth: 165, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN/Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator
    },
    { minWidth: 120, field: 'location_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Id', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'location_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'address1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'address2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'zip_code', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip Code', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 145, field: 'notisphere_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Notisphere ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 160, field: 'last_change_at', hide: false, headerName: 'Last Update', sortable: true, filter: 'agDateColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: ['equals','empty'],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        },
        cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_SHORTDATE_FORMAT })
        }
    },
    {
        minWidth: 80, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            let img = null
            if (params.value === 'Covered') {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.svg')
            } else if (params.value === 'NotCovered') {
                img = require('@/assets/img/icon-mail.svg')
            }
            else if (params.value === 'Invalid') {
                img = require('@/assets/img/close-icon.svg')
            }
            else if (params.value === 'Emailed') {
                img = require('@/assets/img/at-svg.svg')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: supplierLocationActionRenderCell, suppressMovable: true, suppressColumnsToolPanel: true },

]

export const Er_Response_AgGrid =
[{ minWidth: 135, field: 'response_status' ,filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']
    , cellRenderer: (params) => {
        var ob = document.createElement('div')
        ob.innerHTML = `<b-button  class="status-btn mx-auto">
                        <span style="background: ${_findObj(ER_RECALL_STATUS, params.value, 'value', 'color')}; width: 98px;
                        font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                            ${_findObj(ER_RECALL_STATUS, params.value)} 
                        </span> 
                    </b-button>`
        return ob
    }
},
{ minWidth: 150, field: 'supplier_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 150, field: 'distributorName', filterParams: { filterOptions: ['contains', 'blank', 'notBlank'], }, hide: false, headerName: 'Distributor', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
{ minWidth: 110, field: 'recallid', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 100, field: 'recallname', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 110, field: 'notificationfiles', hide: false, headerName: 'Notification & Other Documents', sortable: false, menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    cellRenderer: (params) => {
        var docFrag = document.createDocumentFragment()
        for (let index = 0; index < params.value?.length; index++) {
            let itemdoc = params.value[index]
            let img = null
            let viewclickDom = null
            var ob = document.createElement('div')
            if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                img = require('@/assets/img/word.svg')
            } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                img = require('@/assets/img/adobe.svg')
            }
            else if (itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == '.jpg' || itemdoc.extension == '.svg'  || itemdoc.extension == '.jpeg' || itemdoc.extension == '.gif') {
                img = require('@/assets/img/image.png')
            }
            else if(itemdoc.type == 'video/mp4' || itemdoc.type == 'audio/mp3')
            {
                img = require('@/assets/img/video-icon.jpg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx')
            {
                img = require('@/assets/img/powerpoint.svg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.mimetype == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv')
            {
                img = require('@/assets/img/excel.svg')
            }
            else if(itemdoc.mimetype == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain')
            {
                img = require('@/assets/img/text-format.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                viewclickDom = 'viewclick_' + itemdoc.respondernotificationdocId
                ob.innerHTML = `<img class="m-1" src=${img} height="30" alt="" data-action=${viewclickDom}>
        </img>`
                docFrag.appendChild(ob)
            }
        }
        return docFrag
    }},
{ minWidth: 110, field: 'files',hide: false, headerName: 'Response Documents', sortable: false,menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    cellRenderer: (params) => {
        var docFrag = document.createDocumentFragment()
        for (let index = 0; index < params.value?.length; index++) {
            let itemdoc = params.value[index]
            let img = null
            let viewclickDom = null
            var ob = document.createElement('div')
            if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                img = require('@/assets/img/word.svg')
            } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                img = require('@/assets/img/adobe.svg')
            }
            else if (itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == '.jpg' || itemdoc.extension == '.svg'  || itemdoc.extension == '.jpeg' || itemdoc.extension == '.gif') {
                img = require('@/assets/img/image.png')
            }
            else if(itemdoc.type == 'video/mp4' || itemdoc.type == 'audio/mp3')
            {
                img = require('@/assets/img/video-icon.jpg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx')
            {
                img = require('@/assets/img/powerpoint.svg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.mimetype == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv')
            {
                img = require('@/assets/img/excel.svg')
            }
            else if(itemdoc.mimetype == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain')
            {
                img = require('@/assets/img/text-format.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                viewclickDom = 'viewclick_' + itemdoc.responder_document
                ob.innerHTML = `<img class="m-1" src=${img} height="30" alt="" data-action=${viewclickDom}>
        </img>`
                docFrag.appendChild(ob)
            }
        }
        return docFrag
    }},
{ minWidth: 110, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Site', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 120, field: 'notification_date', hide: false, headerName: 'Notification Date', sortable: true,filter: 'agDateColumnFilter',
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    headerClass: 'dt-header',
    filterParams: {
        browserDatePicker: true,
        minValidYear: 2010,
        maxValidYear: 2030,
        // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
        filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
        defaultOption: 'equals',
        // provide comparator function
        comparator: DateComparator
    }
    , cellRenderer: (params) => {
        if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
            return null
        return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
    }},
{ minWidth: 123, field: 'response_date', hide: false, headerName: 'Response Date', sortable: true,filter: 'agDateColumnFilter',
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    headerClass: 'dt-header',
    filterParams: {
        browserDatePicker: true,
        minValidYear: 2010,
        maxValidYear: 2030,
        // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
        filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
        defaultOption: 'equals',
        // provide comparator function
        comparator: DateComparator
    }
    , cellRenderer: (params) => {
        if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
            return null
        return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
    }},
{minWidth: 110, field: 'username',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Respondent', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
{ minWidth: 110, field: 'disposal_method', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Affected Product', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', sortable: false, cellRenderer: ErResponseActions, suppressMovable: true, suppressColumnsToolPanel: true  },
]