import moment from 'moment-timezone'
import {OUTPUT_DATE_FORMAT, OUTPUT_SHORTDATE_FORMAT} from './commons'
import store from '../store'
export function _findObj(array, search_value, search_key = 'value', get_key = 'text') {
    if( !Array.isArray(array) ){
        return ''
    }
    var obj = array.find( x => x[search_key] === search_value )
    if( typeof obj === 'object' && obj != undefined ) {
        return obj[get_key]
    }
    return ''
}

export  function toLocal(time, options = {outputFormat: OUTPUT_SHORTDATE_FORMAT, inputFormat: 'YYYY-MM-DD HH:mm:ss'}) {
    var date = moment.utc(time, options.inputFormat)
    return date.isValid() ? moment(date).local().format(options.outputFormat) : 'NA'
}
export  function toLocalDate(time, options = {outputFormat: OUTPUT_DATE_FORMAT, inputFormat: 'YYYY-MM-DD HH:mm:ss'}) {
    var date = moment.utc(time, options.inputFormat)
    return date.isValid() ? moment(date).format(options.outputFormat) : 'NA'
}
export  function toLocalDateNA(time, options = {outputFormat: OUTPUT_DATE_FORMAT, inputFormat: 'YYYY-MM-DD HH:mm:ss'}) {
    var date = moment.utc(time, options.inputFormat)
    return date.isValid() ? moment(date).format(options.outputFormat) : ''
}
export function  actionCellRenderer(params) {
    let eGui = document.createElement('div')
    let abc =''
    if(params.data.stt ==='PENDING')
    {
        abc = '<a class="dropdown-item delete"  data-action="delete"> <i class="far fa-trash-alt mr-2"> </i> Delete</a>'
    }
    eGui.innerHTML = `<div>
          <i class="fas fa fa-ellipsis-v actionBtn"  data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
          <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item edit"  data-action="edit"> <i class="far fa-edit mr-2"></i> Edit</a>`+
              abc +' </div> </div>  '
    return eGui
}

export function recallActionRenderCell (params) {
    let eGui = document.createElement('div')
    if(params.data.allow_access){
        eGui.innerHTML =
        `<div>
          <i class="fas fa fa-ellipsis-v actionBtn"  data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item view"  data-action="view"> <i class="far fa fa-eye mr-2"></i> View</a>
              </div>
        </div>`
        // `<div>
        //   <i class="fas fa fa-ellipsis-v actionBtn"  data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
        //       <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton">
        //             <a class="dropdown-item view"  data-action="view"> <i class="far fa fa-eye mr-2"></i> View</a>
        //             <a class="dropdown-item edit" data-action="copy"> <i class="fas fa-copy mr-2"></i> Copy</a>
        //       </div>
        // </div>`
    }
    return eGui
}

export function supplierRecallActionRenderCell(params) {
    let el = document.createElement('div')
    if(params.data.allow_access){
        if (params.data.status == 'CLOSED') {
            el.innerHTML = `<div >
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
      </div> 
    </div>`
        }
        else if (params.data.status == 'OPEN') {
            el.innerHTML = `<div >
      <i class="fas fa fa-ellipsis-v actionBtn"  data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" ></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
      </div> 
    </div>`
        }
        else{
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" ></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
          <a class="dropdown-item view"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
      </div> 
    </div>`
        }
    }
    return el
}

export function providerRecallActionRenderCell(params) {
    let el = document.createElement('div')
    if(params.data.isProviderCreated){
        if(params.data.status_provider == 'COMPLETED'){
            el.innerHTML = `<div>
    <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
        <a class="dropdown-item edit disabled"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
    </div> 
  </div>`
        }
        else{
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
      <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
    </div> 
      </div>
      `
        }
    }
    else{
        el.innerHTML = `<div>
    <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
    </div> 
    </div>
    `
    }
    if(store.state.user.id != params.data.createdUserId)
    {
        el.innerHTML = el.innerHTML.replace('<a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>', '')
        el.innerHTML = el.innerHTML.replace('<a class="dropdown-item edit disabled"> <i class="far fa fa-edit mr-2"></i>  Edit</a>', '')
    }
    if(!params.data.isEdit)
    {
        el.innerHTML = el.innerHTML.replace('<a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>', '')
        el.innerHTML = el.innerHTML.replace('<a class="dropdown-item edit disabled"> <i class="far fa fa-edit mr-2"></i>  Edit</a>', '')
    }
    return el
}
export function supplierDisruptionActionRenderCell(params) {
    let el = document.createElement('div')
    if(params.data.allow_access){
        if (params.data.status == 'CLOSED') {
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
      </div> 
    </div>`
        }
        else if(params.data.status == 'OPEN')
        {
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
      </div> 
    </div>`
        }
        else{
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
          <a class="dropdown-item view"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
      </div> 
    </div>`
        }
    }
    return el
}
export function ErResponseActions(params) {
    let el = document.createElement('div')
    if(store.state.user.id == params.data.userid)
    {
        if (params.data.response_status == 'DRAFT') {
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
      <a class="dropdown-item respond" data-action="respond"><i class="fa fa-reply mr-2"></i>  Respond</a>
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item delete"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
      </div> 
    </div>`
        }
        else if(params.data.response_status == 'ERROR')
        {
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item edit" data-action="edit"> <i class="far fa fa-edit mr-2"></i>  Edit</a>
      <a class="dropdown-item responde" data-action="respond"><i class="fa fa-reply mr-2"></i>  Respond</a>
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item delete"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
      </div> 
    </div>`
        }
        else{
            el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
          <a class="dropdown-item delete"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
      </div> 
    </div>`
        }
    }
    else
    {
        el.innerHTML = `<div>
      <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item view" data-action="view"> <i class="far fa fa-eye mr-2"></i>  View</a>
      </div> 
    </div>`
    }

    return el
}
export function supplierLocationActionRenderCell (params) {
    let eGui = document.createElement('div')
    eGui.innerHTML = `<div >
            <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item edit"  data-action="edit"> <i class="far fa-edit mr-2"></i>Edit</a>
            <a class="dropdown-item view"  data-action="delete"><i class="far fa-trash-alt mr-2"> </i>Delete</a>
            </div> </div>`
    return eGui
}
export function toQueryParams(obj, prefix){
    var str = [],
        p
    for (p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj,p)) {
            var k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p]
            str.push((v !== null && typeof v === 'object') ?
                toQueryParams(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v))
        }
    }
    return str.join('&')
}
export function  getCommunicationType(communicationType) {
    switch(communicationType) {
    case 'RECALL':
        return 'recall'
    case 'PRODUCT DISRUPTION':
        return 'product disruption'
    default:
        return 'recall'
    }

}
