<template>
  <div>
    <b-modal
      v-model="item.enable"
      size="sm"
      centered
      scrollable
      :title="item.title"
      :header-bg-variant="item.variant"
      header-text-variant="light"
      v-for="item in arrNoti"
      :key="item.id"
      :hide-footer="item.hideFooter"
      :hide-header="item.hideHeader"
    >
      <template v-slot:default="{ cancel }">
        <template v-if="item.animationIcon == 'success'">
          <SuccessCheckAnimation class="mt-3"/>
          <div class="text-center">
            <div class="mb-3">{{item.message}}</div>
            <b-button variant="primary" outlined @click="cancel" size="sm" class="px-3">OK</b-button>
          </div>
        </template>
        <template v-else>
          {{item.message}}
        </template>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" outlined @click="cancel" size="sm" class="m-0">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SuccessCheckAnimation from '../part/shared/SuccessCheckAnimation'

export default {
    components: {
        SuccessCheckAnimation
    },
    data() {
        return {
            id: 0,
            arrNoti: []
        }
    },
    methods: {
        addNotify(title, message, variant, options = {}) {
            this.id += 1
            const noti = {
                enable: false,
                id: this.id,
                title: title,
                message: message,
                variant: variant || 'info',
                animationIcon: '',
                hideHeader: false,
                hideFooter: false,
                ...options
            }

            this.arrNoti.push(noti)

            this.$nextTick(()=>{
                noti.enable = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .modal-content{
        border: 0px;
    }
</style>
