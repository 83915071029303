import { AgGridVue } from 'ag-grid-vue'
export default {
    components: {
        AgGridVue
    },
    props: {
    //name for the table
        tableName: { type: String, default: '' },
        addMoreLabel: { type: String, default: 'Add' },
        columnDefs: { type: Array, default: [], required: true },
        gridOptions: { type: Object, default: () => { }, required: false },
        items: { type: Array, default: [], required: true },
        total: { type: Number, default: 0, required: true },
        rowModelType: { type: String, default: 'serverSide', required: true },
        showRecallReportTitle: { type: String, default: 'Recalls Report' },
        showAddMore: { type: Boolean, default: false },
        showRecallReport: { type: Boolean, default: false },
        showCombineReport: { type: Boolean, default: false },
        showProviderOrgReport: { type: Boolean, default: false },
        showV: { type: Boolean, default: false },
        showE: { type: Boolean, default: false },
        showI: { type: Boolean, default: false },
        showNC: { type: Boolean, default: false },
    },
    data() {
        return {
            overlayNoRowsTemplate: null,
            _gridOptions: this.gridOptions,
            pagination: false,
            // cacheBlockSize: 10,
            // paginationPageSize: 50,
            rowHeight: 50,
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            queryParams: {
                per_page: 50,
                page: 1,
            },
            gridApi: null,
            columnApi: null
        }
    },
    created() {
        this._gridOptions = {
            ...this.gridOptions,
            ...{
                suppressDragLeaveHidesColumns: true,
                domLayout: 'autoHeight',
                enableCellChangeFlash: false,
                suppressRowTransform: true,
                suppressRowClickSelection: true,
                rowModelType: 'serverSide',
                cacheBlockSize: 100,
                maxConcurrentDatasourceRequests: 2,
                maxBlocksInCache: 10,
                headerHeight: 40,
                rowHeight: 32,
                enableRangeSelection: true,
                sortingOrder: ['asc', 'desc', null],
                enableBrowserTooltips: true,
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    suppressVerticalScroll: true,
                    resizable: true,
                    flex: 1,
                    wrapText: true,
                    autoHeight: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    filterParams: { suppressAndOrCondition: true, values: [], filterOptions: ['contains'],buttons: ['apply', 'reset'], closeOnApply: true,}//buttons: ['apply', 'reset'], closeOnApply: true,
                },
                onColumnVisible: this.onColumnVisible,
                onColumnMoved: this.onColumnMoved,
                onColumnResized: this.onColumnResized,
                onColumnPinned: this.onColumnPinned
            }
        }
        this.overlayNoRowsTemplate =
      '<label style="padding: 10px; font-size:16px; text-align: left; width:100%;height:100%;">No record found</label>'
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
            })
            this.onGridReady({ api: this.gridApi, columnApi: this.columnApi })
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
            })
            this.onGridReady({ api: this.gridApi, columnApi: this.columnApi })
        },
        queryParams: {
            deep: true,
            handler(val) {
                this.$emit('update:searchQuery', {
                    pageNumber: this.currentPage,
                    pageSize: this.perPageEmit,
                    sort: val.sort,
                })
            }
        },

    },
    methods: {
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        onColumnMoved(params) {
            this.$emit('saveState', params)
        },
        onColumnResized(params) {
            this.$emit('saveState', params)
        },
        onColumnPinned(params) {
            this.$emit('saveState', params)
        },
        onColumnVisible(params) {
            this.$emit('saveState', params)
        },
        onGridReady(params) {
            this.gridApi = params.api
            this.columnApi = params.columnApi
            this.$emit('fetchData', params)
        },
        cellClicked(event) {
            this.$emit('onCellClicked', event)
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addProviderClick() {
            this.$emit('addProviderClick')
        },
        addDelegateReportClick() {
            this.$emit('addDelegateReportClick')
        },
        addDelegateCSVReportClick() {
            this.$emit('addDelegateCSVReportClick')
        },
        btnRecallCSVClick() {
            this.$emit('btnRecallCSVClick')
        },
        addMoreClick() {
            this.$emit('addMoreClick')
        },
        btnRecallXLSXClick() {
            this.$emit('btnRecallXLSXClick')
        },
        btnProviderAccountCSVClick() {
            this.$emit('btnProviderAccountCSVClick')
        },
        btnProviderAccountXLSXClick() {
            this.$emit('btnProviderAccountXLSXClick')
        },
        addVerifyClick() {
            this.$emit('addVerifyClick')
        },
        addEmailedClick() {
            this.$emit('addEmailedClick')
        },
        addInvalidClick() {
            this.$emit('addInvalidClick')
        },
        addCoveredClick() {
            this.$emit('addCoveredClick')
        },
        DisableClick() {
            this.$emit('DisableClick')
        }
    }
}