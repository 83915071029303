import { faLeaf } from '@fortawesome/free-solid-svg-icons'

export default {
    props: {
        isUserProfile: { type: Boolean, default: false },
        title: { type: String, default: 'Table Name' },
        btnLabel: { type: String, default: 'Add' },
        btnproviderLabel: { type: String, default: 'Providers' },
        btnVerifyLabel: { type: String, default: 'Covered' },
        btnEmailLabel: { type: String, default: 'Emailed' },
        btnInvalidLabel: { type: String, default: 'Invalid' },
        btnCoveredLabel: { type: String, default: 'Not Covered' },
        btnIcon: { type: String, default: 'fas fa-plus' },
        showBtn: { type: Boolean, default: false },
        showDBtn: { type: Boolean, default: false },
        showProviderBtn: { type: Boolean, default: false },
        showCombineReportBtn: { type: Boolean, default: false },
        showErReportBtn: { type: Boolean, default: false },
        showRecallReportBtn: { type: Boolean, default: false },
        showRecallReportTitle: { type: String, default: 'Recalls Report' },
        showProviderOrgReportBtn: { type: Boolean, default: false },
        showSupplierBtn: { type: Boolean, default: false },
        showSupplierLocationBtn: { type: Boolean, default: false },
        showCBtn: { type: Boolean, default: false },
        showVBtn: { type: Boolean, default: false },
        showEBtn: { type: Boolean, default: false },
        showIBtn: { type: Boolean, default: false },
        terms: { type: Boolean, default: false },
        showKnownproviderbtn: { type: Boolean, default: false },
    },
    data() {
        return {
            disabled: false
        }
    },
    computed: {
        isDisabled: function(){
            return !this.disabled
        }
    },
    methods: {
        isDisabledbtn: function(){
            if(this.disabled)
            {
                this.disabled = false
            }
            else
            {
                this.disabled = true
            }
            return this.disabled ? !this.terms : this.terms
        },
        isDisabled1: function(){
            if(this.terms)
            {
                this.terms = false
            }
            else
            {
                this.terms = true
            }
            return this.terms
        },
        btnClick(){
            this.$emit('btnClick')
        },
        btnknownProviderClick(){
            this.$emit('btnknownProviderClick')
        },
        btnDClick() {
            this.$emit('btnDClick')
        },
        btnProviderClick() {
            this.$emit('btnProviderClick')
        },
        exportsupplierlocationReport() {
            this.$emit('exportsupplierlocationReport')
        },
        downloadsupplierlocationReport() {
            this.$emit('downloadsupplierlocationReport')
        },

        btnaddDelegateReportClick() {
            this.$emit('btnaddDelegateReportClick')
        },
        btnaddDelegateCSVReportClick() {
            this.$emit('btnaddDelegateCSVReportClick')
        },

        btnErCSVClick() {
            this.$emit('btnErCSVClick')
        },
        btnErXLSXClick() {
            this.$emit('btnErXLSXClick')
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addProviderClick() {
            this.$emit('addProviderClick')
        },
        addDelegateReportClick() {
            this.$emit('addDelegateReportClick')
        },
        addDelegateCSVReportClick() {
            this.$emit('addDelegateCSVReportClick')
        },
        btnRecallCSVClick() {
            this.$emit('btnRecallCSVClick')
        },
        btnRecallXLSXClick() {
            this.$emit('btnRecallXLSXClick')
        },
        btnProviderAccountCSVClick() {
            this.$emit('btnProviderAccountCSVClick')
        },
        btnProviderAccountXLSXClick() {
            this.$emit('btnProviderAccountXLSXClick')
        },
        btnVerify() {
            this.$emit('btnVerify')
        },
        btnEmailed() {
            this.$emit('btnEmailed')
        },
        btnInvalid() {
            this.$emit('btnInvalid')
        },
        btnCovered() {
            this.$emit('btnCovered')
        },
        btnDisbled() {
            return this.isDisabledbtn()
        }
    }
}